import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public toastController: ToastController) {}

  async presentToast(value) {
    const toast = await this.toastController.create({
      message: value.message,
      color: value.color,
      duration: value.time ? value.time : 1500,
      position: 'top',
      cssClass: 'toast',
    });
    toast.present();
  }

  async presentToastWithOptions(value) {
    const toast = await this.toastController.create({
      message: value.message,
      color: value.color,
      duration: value.time,
      position: 'top',
      cssClass: 'toast',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  async presentToastCopy() {
    const toast = await this.toastController.create({
      message: 'Código de barras copiado com sucesso!!!',
      color: 'light',
      duration: 1000,
      position: 'top',
      cssClass: 'toast',
    });
    toast.present();
  }
}
