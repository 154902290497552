/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable object-shorthand */
/* eslint-disable one-var */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import {
  AlertController,
  AnimationController,
  MenuController,
  ModalController,
  Platform,
} from '@ionic/angular';

// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { HeaderColor } from '@ionic-native/header-color/ngx';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HeaderColor } from '@ionic-native/header-color/ngx';

import { AuthenticationService } from './services/apis/authentication/authentication.service';
import { ToastService } from './services/useful/toast/toast.service';
import { UserService } from './services/apis/user/user.service';
import { environment } from 'src/environments/environment';
import { ProgressBarService } from './services/useful/loading/progress-bar/progress-bar.service';
import * as $ from 'jquery';
import { ModalBoletoPage } from './pages/client/modal/modal-boleto/modal-boleto.page';
import { ModalNotificationPage } from './pages/client/modal/modal-notification/modal-notification.page';
import { VersionService } from './services/apis/version/version.service';
import CryptoJS from 'crypto-js'

declare global {
  interface Window {
    ActiveXObject: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private logo: any = 'assets/logo/Logo_EasyPlan.png';
  user: any;
  menuValue: any = 'dashboard';
  isCPF = false;
  public notification = [];
  public interval: any;
  public hash: any;
  public cipher: any;

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private headerColor: HeaderColor,
    private authenticationService: AuthenticationService,
    private router: Router,
    private menu: MenuController,
    private toast: ToastService,
    public alertController: AlertController,
    private userService: UserService,
    private progressBar: ProgressBarService,
    private iab: InAppBrowser,
    private headerColor: HeaderColor,
    public modalController: ModalController,
    public animationCtrl: AnimationController,
    private versionServer: VersionService
  ) {
    this.hash = location.hash
    this.InitializeApp();
  }

  InitializeApp() {
    this.platform.ready().then(() => {
      this.headerColor.tint('#00a7ba');
      // this.splashScreen.hide();
      this.menu.swipeGesture(false);
      this.Authentication();
      // this.versionServer.CheckUpdate();
    });
  }

  async Authentication() {
    this.authenticationService.authenticationState.subscribe(async (state) => {
      if (state) {
        this.router.navigate(['auth']);
      }
      else {
        this.user = await JSON.parse(
          !this.platform.is('android') && !this.platform.is('ios')
            ? environment.storageWeb.getItem('USER')
            : environment.storageMobile.getItem('USER')
        );
        this.setInterval();
        this.GetUserNotification();
        let navigationExtras: NavigationExtras = {
          queryParams: {
            hash: this.hash,
          },
          queryParamsHandling: this.hash ? 'merge' : 'preserve',
        };
        this.router.navigate(['presentation'], navigationExtras);
        this.checkPermissions();
        this.hash = '';
        $(`#${this.menuValue}`).addClass('active');
      }
    });
  }

  checkPermissions() {
    this.authenticationService.GetUserPermissions().subscribe(
      async (res) => {
        if (res.sucesso) {
          this.authenticationService.permissions = await res.user.users_permissions[0];
          await environment.storageWeb.setItem(
            'USER_PERMISSIONS',
            this.criptografar(JSON.stringify(this.authenticationService.permissions))
          );
        }
      },
      (err) => { }
    );
  }

  criptografar(val) {
    this.cipher = CryptoJS.AES.encrypt(val, 'GjNn|%tQ_3i-n2FKwA>q2NK@L3£!{K9m>£^>3c;9\]}@6EHyu%').toString();
    return this.cipher
  }

  setInterval() {
    this.interval = setInterval(() => {
      if (location.hash !== '#/auth') {
        this.authenticationService.VerifyToken().subscribe(
          (res) => {
            if (res.sucesso) {
            } else {
              clearInterval(this.interval);
              this.modalController.dismiss();
              this.authenticationService.Logout();
              this.modalController.dismiss();
              const toast = {
                message: 'Sua sessão expirou. Faça login para acessar novamente!',
                color: 'warning',
              };
              this.toast.presentToastWithOptions(toast);
            }
          },
          (err) => {
            clearInterval(this.interval);
            this.modalController.dismiss();
            this.authenticationService.Logout();
            this.modalController.dismiss();
            const toast = {
              message: 'Sua sessão expirou. Faça login para acessar novamente!',
              color: 'warning',
            };
            this.toast.presentToastWithOptions(toast);
          }
        );
      }

    }, 120000);
  }

  async GoModalNotification() {
    this.menu.close('left');
    if (this.notification.length > 0) {
      const enterAnimation = (baseEl: any) => {
        const wrapperAnimation = this.animationCtrl
          .create()
          .addElement(baseEl.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, opacity: '0', transform: 'scale(0)' },
            { offset: 1, opacity: '1', transform: 'scale(1)' },
          ]);

        return this.animationCtrl
          .create()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(200)
          .addAnimation([wrapperAnimation]);
      };

      const leaveAnimation = (baseEl: any) =>
        enterAnimation(baseEl).direction('reverse');

      const modal = await this.modalController.create({
        component: ModalNotificationPage,
        componentProps: {
          notification: this.notification,
        },
        cssClass: 'classModalNotification',
        enterAnimation,
        leaveAnimation,
        // backdropDismiss: false,
      });

      modal.onWillDismiss().then((ev) => {
        if (ev.data.boleto) {
          // this.GoModalBoleto(ev.data.boleto);
        } else {
        }
      });

      return await modal.present();
    } else {
      this.presentAlertNONotification();
    }
  }

  async presentAlertNONotification() {
    const alert = await this.alertController.create({
      header: 'Notificação',
      message: 'Usuário não possui notificação',
      buttons: ['OK'],
    });

    await alert.present();
  }

  GetUserNotification() {
    // this.userService.GetUserInfo().subscribe(
    //   (res) => {
    //     setTimeout(() => {
    //       // res = environment.userMock;
    //       if (res.sucesso === true) {
    //         res.modelo.parcelas.forEach((element) => {
    //           if (
    //             element.situacao === 'Aguardando Liquidação' ||
    //             element.situacao === 'Vencida'
    //           ) {
    //             this.notification.push(element);
    //           }
    //         });
    //       } else {
    //       }
    //     }, 100);
    //   },
    //   (error) => {}
    // );
  }
}
