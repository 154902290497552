/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ToastService } from 'src/app/services/useful/toast/toast.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import {
  FileTransfer,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import * as moment from 'moment';
moment.locale('pt-br');

import * as $ from 'jquery';
import * as print from 'print-js';
import { BonusesService } from 'src/app/services/apis/administrator/bonuses/bonuses.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-modal-bonuses',
  templateUrl: './modal-bonuses.page.html',
  styleUrls: ['./modal-bonuses.page.scss'],
})
export class ModalBonusesPage {
  @Input('id') id;
  public init = false;
  public sizecol1 = 8;
  public sizecol2 = 4;
  public sizeLegenda = 3;
  public sizeFilter = 6;
  public sizeFilterCol = 5;
  public sizebtnUpdate = 2;
  public resmobile = true;
  public restablet = true;
  public mes = moment().format('MM');
  public dataInicial: any = moment(new Date()).format('YYYY-MM-DD');
  public dataFinal: any = moment(new Date()).format('YYYY-MM-DD');
  public bonuses = [];
  public arquivoUrl = '';

  constructor(
    public modalController: ModalController,
    private toast: ToastService,
    public fileOpener: FileOpener,
    private boService: BonusesService,
    private platform: Platform,
    private iab: InAppBrowser
  ) { }

  async ionViewWillEnter() {
    this.resize();
    this.GetBonuses();
  }

  ionViewWillLeave() {
    this.init = false;
    this.bonuses = [];
  }

  resize() {
    if ($(window).innerWidth() < 768) {
      this.sizecol1 = 6;
      this.sizecol2 = 6;
      this.sizeLegenda = 6;
      this.sizeFilter = 12;
      this.sizeFilterCol = 10;
      this.sizebtnUpdate = 2;
      this.resmobile = false;
    } else if ($(window).innerWidth() > 767 && $(window).innerWidth() < 1025) {
      this.sizecol1 = 6;
      this.sizecol2 = 6;
      this.sizeLegenda = 6;
      this.sizeFilter = 12;
      this.sizeFilterCol = 10;
      this.sizebtnUpdate = 2;
      this.restablet = false;
    } else if ($(window).innerWidth() > 1024 && $(window).innerWidth() < 1440) {
      this.sizecol1 = 8;
      this.sizecol2 = 4;
      this.sizeFilter = 8;
      this.sizebtnUpdate = 1.5;
    } else {
      this.sizecol1 = 8;
      this.sizecol2 = 4;
      this.sizeLegenda = 3;
      this.sizeFilter = 6;
      this.sizeFilterCol = 5;
      this.sizebtnUpdate = 1.5;
      this.resmobile = true;
    }

    $(window).on('resize', () => {
      if ($(window).innerWidth() < 768) {
        this.sizecol1 = 6;
        this.sizecol2 = 6;
        this.sizeLegenda = 6;
        this.sizeFilter = 12;
        this.sizeFilterCol = 10;
        this.sizebtnUpdate = 2;
        this.resmobile = false;
      } else if (
        $(window).innerWidth() > 767 &&
        $(window).innerWidth() < 1025
      ) {
        this.sizecol1 = 6;
        this.sizecol2 = 6;
        this.sizeLegenda = 6;
        this.sizeFilter = 12;
        this.sizeFilterCol = 10;
        this.sizebtnUpdate = 2;
        this.restablet = false;
      } else if (
        $(window).innerWidth() > 1024 &&
        $(window).innerWidth() < 1440
      ) {
        this.sizecol1 = 8;
        this.sizecol2 = 4;
        this.sizeFilter = 8;
        this.sizebtnUpdate = 1.5;
      } else {
        this.sizecol1 = 8;
        this.sizecol2 = 4;
        this.sizeLegenda = 3;
        this.sizeFilter = 6;
        this.sizeFilterCol = 5;
        this.sizebtnUpdate = 1.5;
        this.resmobile = true;
      }
    });
  }

  GetBonuses() {
    this.boService.GetLoteBonuses(this.id).subscribe(
      (res) => {
        if (res.sucesso) {
          this.arquivoUrl = res.loteBonuses.arquivoUrl;
          this.bonuses = res.loteBonuses.bonuses;
          this.init = true;
        } else {
          this.init = true;
        }
      },
      (err) => {
        let toast;
        toast = {
          message: `${err.error.message}`,
          color: 'danger',
          time: 3000
        };
        this.toast.presentToast(toast);;
        this.init = true;
      }
    );
  }

  GoClose() {
    this.modalController.dismiss({
      // dismissed: ev
    });
  }

  async Download(link) {
    if (this.platform.is('android')) {
      const browser = await this.iab.create(
        link,
        '_system',
        'location=yes,closebuttoncolor=#ffffff,hidenavigationbuttons=yes,hideurlbar=yes,Navigationbuttoncolor=#3c6070,toolbarcolor=#3c6070'
      );
      browser.show();
    } else if (this.platform.is('ios')) {
      const browser = await this.iab.create(
        link,
        '_system',
        'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
      );
      browser.show();
    } else {
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = link;
        save.target = '_blank';

        const evt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }

      // for IE < 11
      else if (!!window.ActiveXObject && document.execCommand) {
        const wwindow = window.open(link, '_blank');
        wwindow.document.close();
        wwindow.document.execCommand('SaveAs', true, link);
        wwindow.close();
      }
    }
  }

  FormatData(val) {
    if (val === '00/00/00' || val === '' || val === null || val === 'null') {
      return 'INDEFINIDO';
    } else {
      return `${val.slice(8, 10)}/${val.slice(5, 7)}/${val.slice(0, 4)}`;
    }
  }

  FormatBonuse(val) {
    let bonus = Number(val);
    if (bonus) {
      return bonus.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    } else {
      if (bonus === 0) {
        return 'R$ 0,00';
      } else {
        return 'não identificado';
      }
    }
  }
}
