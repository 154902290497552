/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import * as moment from 'moment';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private platform: Platform, private httpClient: HttpClient) { }

  GetSales(val?, page?) {
    // let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios')) ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    // let data;
    // if (val) {
    //   data = val;
    // } else {
    //   data = ``;
    // }
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: environment.Authorization,
    //   }),
    // };

    // return this.httpClient
    //   .get(
    //     `${environment.AUTH_SERVER_ADDRESS}/Contracts/${user.taxvat}${data}`,
    //     httpOptions
    //   )
    //   .pipe(tap(async (res: any) => {}));

    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let userType;
    if (user.taxvat.length === 11) {
      userType = 'produtorCPF';
    } else {
      userType = 'corretoraCNPJ';
    }
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();
    if (page !== '' && page !== undefined && page !== null) {
      formData.append('pagina', page);
    } else {
      formData.append('pagina', '0');
    }

    formData.append('paginacao', '-1');
    if (dados.filtro) {
      formData.append(
        'filtros',
        `${userType}.ToString().Equals("${user.taxvat}")${dados.filtro}`
      );
    } else {
      formData.append(
        'filtros',
        `${userType}.ToString().Equals("${user.taxvat}")`
      );
    }
    if (dados.dataInicio) {
      formData.append('dataInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataInicio',
        `01/${moment(new Date()).format('MM/YYYY')}`
        // `01/01/${moment(new Date()).format('YYYY')}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataFim', dados.dataFim);
    } else {
      formData.append('dataFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ExtratoVendasPorData`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetSalesSearch(val?) {
    // let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    // let data;
    // if (val) {
    //   data = val;
    // } else {
    //   data = ``;
    // }
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: environment.Authorization,
    //   }),
    // };

    // return this.httpClient
    //   .get(
    //     `${environment.AUTH_SERVER_ADDRESS}/Contracts/${user.taxvat}${data}`,
    //     httpOptions
    //   )
    //   .pipe(tap(async (res: any) => {}));

    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let userType;
    if (user.taxvat.length === 11) {
      userType = 'produtorCPF';
    } else {
      userType = 'corretoraCNPJ';
    }
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();

    formData.append('pagina', '0');

    formData.append('paginacao', '-1');
    if (dados.filtro) {
      formData.append(
        'filtros',
        `${userType}.ToString().Equals("${user.taxvat}")${dados.filtro}`
      );
    } else {
      formData.append(
        'filtros',
        `${userType}.ToString().Equals("${user.taxvat}")`
      );
    }
    if (dados.dataInicio) {
      formData.append('dataInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataInicio',
        `01/${moment(new Date()).format('MM/YYYY')}`
        // `01/01/${moment(new Date()).format('YYYY')}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataFim', dados.dataFim);
    } else {
      formData.append('dataFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ExtratoVendasPorData`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCommissions(val?) {
    // let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    // let data;
    // if (val) {
    //   data = val;
    // } else {
    //   data = ``;
    // }
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: environment.Authorization,
    //   }),
    // };

    // return this.httpClient
    //   .get(
    //     `${environment.AUTH_SERVER_ADDRESS}/Commissions/${user.taxvat}${data}`,
    //     httpOptions
    //   )
    //   .pipe(tap(async (res: any) => {}));

    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let userType;
    if (user.taxvat.length === 11) {
      userType = 'produtorCPF';
    } else {
      userType = 'corretoraCNPJ';
    }
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();

    formData.append('pagina', '0');

    formData.append('paginacao', '-1');
    if (dados.filtro) {
      formData.append(
        'filtros',
        `${userType}.ToString().Equals("${user.taxvat}")&&situacao.ToString().Equals("Paga")${dados.filtro}`
      );
    } else {
      formData.append(
        'filtros',
        `${userType}.ToString().Equals("${user.taxvat}")&&situacao.ToString().Equals("Paga")`
      );
    }
    if (dados.dataInicio) {
      formData.append('dataInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataInicio',
        `01/${moment(new Date()).format('MM/YYYY')}`
        // `01/01/${moment(new Date()).format('YYYY')}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataFim', dados.dataFim);
    } else {
      formData.append('dataFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ExtratoComissaoPorDataPagamento`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCommissionsSearch(val?) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let userType;
    if (user.taxvat.length === 11) {
      userType = 'produtorCPF';
    } else {
      userType = 'corretoraCNPJ';
    }
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();

    formData.append('pagina', '0');

    formData.append('paginacao', '-1');
    if (dados.filtro) {
      formData.append(
        'filtros',
        `${userType}.ToString().Equals("${user.taxvat}")${dados.filtro}`
      );
    } else {
      formData.append(
        'filtros',
        `${userType}.ToString().Equals("${user.taxvat}")`
      );
    }
    if (dados.dataInicio) {
      formData.append('dataInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataInicio',
        `01/${moment(new Date()).format('MM/YYYY')}`
        // `01/01/${moment(new Date()).format('YYYY')}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataFim', dados.dataFim);
    } else {
      formData.append('dataFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ExtratoComissaoPorDataPagamento`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetQuestions() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
      }),
    };

    return this.httpClient
      .get(`${environment.AUTH_SERVER_ADDRESS}/afrest12/`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  GetFiles() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .get(`${environment.AUTH_SERVER_ADDRESS}/afrest13/`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  GetFile(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
      }),
    };

    return this.httpClient
      .get(`${environment.AUTH_SERVER_ADDRESS}/afrest13/${id}`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  GetOperadoras(estadoID?) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: environment.Authorization,
    //   }),
    // };

    // return this.httpClient
    //   .get(`${environment.AUTH_SERVER_ADDRESS}/afrest14/`, httpOptions)
    //   .pipe(tap(async (res: any) => {}));

    let formData = new FormData();

    formData.append('pagina', '0');
    formData.append('paginacao', '-1');

    if (estadoID) {
      formData.append('estadoID', estadoID);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ListarOperadora`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetProdutos(operadoraID) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token
          ? user.token
          : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
      withCredentials: true,
    };
    let formData = new FormData();

    formData.append('pagina', '0');
    formData.append('paginacao', '-1');

    formData.append(
      'filtros',
      `operadoraID.ToString().Equals("${operadoraID}")`
    );

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/Produto/Listar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBanks() {
    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    // };

    // return this.httpClient
    //   .get(`https://brasilapi.com.br/api/banks/v1`, httpOptions)
    //   .pipe(tap(async (res: any) => {}));
    let formData = new FormData();
    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/Banco/Listar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBankDetail(code) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpClient
      .get(`https://brasilapi.com.br/api/banks/v1/${code}`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  GetTipoConta() {
    let formData = new FormData();
    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/TipoContaBancaria/Listar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUF() {
    let formData = new FormData();
    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/Utils/ListarEstados`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCidade(val) {
    let formData = new FormData();
    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    formData.append('filtros', `estadoID==${val}`);
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/Utils/ListarCidades`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCorretoras(val) {
    let formData = new FormData();
    formData.append('estadoID', val);
    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ListarCorretoraPorUF`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetSupervisores(val) {
    let formData = new FormData();
    formData.append('corretoraID', val);
    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ListarSupervisor`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUser() {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let formData = new FormData();
    formData.append('id', user.adesaoID);
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/Produtor/Pagina`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetInfo() {
    // eslint-disable-next-line new-parens
    let now = new Date();

    const httpOptions = {
      headers: new HttpHeaders({
        Action: 'propostasAssinadas',
        'Planium-Apikey':
          // '00f2b2907e2b41368c0fabfdaaacf936381cc70b17c04af2c5fb5d78efa7eaf5',
          '59c31963835d430dddff8ea0e89a45956baddb80f283417cf4c8ed5f4ef0e149',
      }),
    };

    return this.httpClient
      .get(
        `https://dnv-api.planium.io/test/custom/v1?data_inicio=${now.getFullYear()}-01-01&data_fim=${now.getFullYear()}-12-31`,
        // `https://easyplan.test.planium.io/custom/easyplan/api/propostasAssinadas/propostasAssinadas.api.php?data_inicio=${now.getFullYear()}-01-01&data_fim=${now.getFullYear()}-12-31`,
        // `https://easyplan.planium.io/custom/easyplan/api/propostasAssinadas/propostasAssinadas.api.php?data_inicio=${now.getFullYear()}-01-01&data_fim=${now.getFullYear()}-12-31`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBonuses(val?) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();

    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    formData.append('produtorID', user.produtorID);
    if (dados.filtro) {
      formData.append('filtros', `${dados.filtro.replace('&&', '')}`);
    }
    if (dados.dataInicio) {
      formData.append('dataInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataInicio',
        `01/${moment(new Date()).format('MM')}/${moment(new Date()).format(
          'YYYY'
        )}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataFim', dados.dataFim);
    } else {
      formData.append('dataFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ListarBonificacoesPendentePagamentoCliente`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBonusesSearch(val?) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();

    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    formData.append('produtorID', user.produtorID);
    if (dados.filtro) {
      formData.append('filtros', `${dados.filtro.replace('&&', '')}`);
    }
    if (dados.dataInicio) {
      formData.append('dataInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataInicio',
        `01/${moment(new Date()).format('MM')}/${moment(new Date()).format(
          'YYYY'
        )}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataFim', dados.dataFim);
    } else {
      formData.append('dataFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ListarBonificacoesPendentePagamentoCliente`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBonusesPagas(val?) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();

    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    formData.append('produtorID', user.produtorID);
    if (dados.filtro) {
      formData.append('filtros', `${dados.filtro.replace('&&', '')}`);
    }
    if (dados.dataInicio) {
      formData.append('dataVigenciaInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataVigenciaInicio',
        `01/${moment(new Date()).format('MM')}/${moment(new Date()).format(
          'YYYY'
        )}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataVigenciaFim', dados.dataFim);
    } else {
      formData.append('dataVigenciaFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/BonificacoesPagas`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBonusesPagasSearch(val?) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();

    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    formData.append('produtorID', user.produtorID);
    if (dados.filtro) {
      formData.append('filtros', `${dados.filtro.replace('&&', '')}`);
    }
    if (dados.dataInicio) {
      formData.append('dataVigenciaInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataVigenciaInicio',
        `01/${moment(new Date()).format('MM')}/${moment(new Date()).format(
          'YYYY'
        )}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataVigenciaFim', dados.dataFim);
    } else {
      formData.append('dataVigenciaFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/BonificacoesPagas`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetReport(val?) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const dados = {
      filtro: null,
      dataInicio: null,
      dataFim: null,
    };
    if (val) {
      dados.filtro = val.filtro;
      dados.dataInicio = val.dataInicio;
      dados.dataFim = val.dataFim;
    }
    let formData = new FormData();

    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    // formData.append('corretoraID', user.produtorID);
    formData.append('corretoraID', '07835f32-a3b9-11eb-aae5-020506faab1c');
    if (dados.filtro) {
      formData.append('filtros', `${dados.filtro.replace('&&', '')}`);
    }
    if (dados.dataInicio) {
      formData.append('dataInicio', dados.dataInicio);
    } else {
      formData.append(
        'dataInicio',
        `01/${moment(new Date()).format('MM')}/${moment(new Date()).format(
          'YYYY'
        )}`
      );
    }
    if (dados.dataFim) {
      formData.append('dataFim', dados.dataFim);
    } else {
      formData.append('dataFim', `${moment(new Date()).format('L')}`);
    }

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/ListarLotesSemNota`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  ExportReport(id) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token
          ? user.token
          : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
      withCredentials: true,
    };
    return this.httpClient
      .get(
        `https://administradora.easyplan.com.br/Bonificacao/ExportarExtratoBonificacoesAPagar?id=1a8c59e1-5882-11ee-abcf-020506faab1c`,
        // `https://administradora.easyplan.com.br/Bonificacao/ExportarExtratoBonificacoesAPagar?id=${id}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UploadNF(file, id) {
    let formData = new FormData();
    formData.append('comissaoLotePagamentoCorretoraID', id);
    formData.append('file', file.arquivo, file.name);
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/SalvarNotaFiscalCorretora`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetVersion() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.httpClient
      .get(`https://links.fortumcaps.com/version.json`)
      .pipe(tap(async (res: any) => { }));
  }

  GetRegras() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.httpClient
      .get(`https://links.fortumcaps.com/regraspagamento.json`)
      .pipe(tap(async (res: any) => { }));
  }

  GetVigenciaFechamento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.httpClient
      .get(`https://links.fortumcaps.com/vigenciaefechamento.json`)
      .pipe(tap(async (res: any) => { }));
  }

  GetDadosBoleto(boletoID) {
    let formData = new FormData();
    formData.append('boletoID', boletoID);
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaBeneficiario/VisualizarDadosBoleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCorretoraEndereco(id) {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token
          ? user.token
          : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
      withCredentials: true,
    };
    let formData = new FormData();

    formData.append('pagina', '0');
    formData.append('paginacao', '-1');
    formData.append('filtros', `corretoraID.ToString().Equals("${id}")`);

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/CorretoraEndereco/Listar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }
}
