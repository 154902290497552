import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from 'src/app/services/apis/authentication/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AuthAgentGuard implements CanActivate {

  constructor(
    public auth: AuthenticationService,
  ) { }

  canActivate(): boolean {
    return this.auth.IsAgentAuthenticated();
  }

}
