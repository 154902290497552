/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  public isOpen: any = false;
  public interval: any;

  constructor(
    private httpClient: HttpClient,
    public alertController: AlertController,
    private appVersion: AppVersion,
    private plt: Platform,
    private market: Market,
    private iab: InAppBrowser
  ) {}

  GetVersion() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.httpClient
      .get(`https://links.fortumcaps.com/version_corretor.json`)
      .pipe(tap(async (res: any) => {}));
  }

  CheckUpdate() {
    this.GetVersion().subscribe(
      async (res) => {
        const versionNumber = await this.appVersion.getVersionNumber();
        console.log(versionNumber);
        const splittedVersion = versionNumber.split('.');

        if (this.plt.is('android') && !this.plt.is('mobileweb')) {
          const serverVersion = res.android.current.split('.');

          if (serverVersion[0] > splittedVersion[0]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.android.majorMsg.title,
                res.android.majorMsg.msg,
                res.android.majorMsg.btn
              );
              this.SetInterval();
            }
          } else if (serverVersion[1] > splittedVersion[1]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.android.majorMsg.title,
                res.android.majorMsg.msg,
                res.android.majorMsg.btn
              );
              this.SetInterval();
            }
          } else if (serverVersion[2] > splittedVersion[2]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.android.majorMsg.title,
                res.android.majorMsg.msg,
                res.android.majorMsg.btn
              );
              this.SetInterval();
            }
          } else {
            this.isOpen = false;
            clearInterval(this.interval);
          }
        } else if (this.plt.is('ios') && !this.plt.is('mobileweb')) {
          const serverVersion = res.ios.current.split('.');

          if (serverVersion[0] > splittedVersion[0]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.ios.majorMsg.title,
                res.ios.majorMsg.msg,
                res.ios.majorMsg.btn
              );
              this.SetInterval();
            }
          } else if (serverVersion[1] > splittedVersion[1]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.ios.majorMsg.title,
                res.ios.majorMsg.msg,
                res.ios.majorMsg.btn
              );
              this.SetInterval();
            }
          } else if (serverVersion[2] > splittedVersion[2]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.ios.majorMsg.title,
                res.ios.majorMsg.msg,
                res.ios.majorMsg.btn
              );
              this.SetInterval();
            }
          } else {
            this.isOpen = false;
            clearInterval(this.interval);
          }
        } else if (this.plt.is('desktop') || this.plt.is('mobileweb')) {
          const serverVersion = res.web.current.split('.');

          if (serverVersion[0] > splittedVersion[0]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.web.majorMsg.title,
                res.web.majorMsg.msg,
                res.web.majorMsg.btn
              );
              this.SetInterval();
            }
          } else if (serverVersion[1] > splittedVersion[1]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.web.majorMsg.title,
                res.web.majorMsg.msg,
                res.web.majorMsg.btn
              );
              this.SetInterval();
            }
          } else if (serverVersion[2] > splittedVersion[2]) {
            if (!this.isOpen) {
              this.presentAlert(
                res.web.majorMsg.title,
                res.web.majorMsg.msg,
                res.web.majorMsg.btn
              );
              this.SetInterval();
            }
          } else {
            this.isOpen = false;
            clearInterval(this.interval);
          }
        }
      },
      (error) => {}
    );
  }

  async presentAlert(header, message, btn) {
    const alert = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      mode: 'ios',
      buttons: [btn],
    });

    await alert.present();
    this.isOpen = true;

    alert.onWillDismiss().then(async () => {
      this.isOpen = false;
      if (this.plt.is('android') && !this.plt.is('mobileweb')) {
        this.market.open('br.com.easyplan.corretor.app');
      } else if (this.plt.is('ios') && !this.plt.is('mobileweb')) {
        const browser = await this.iab.create(
          `itms-apps://itunes.apple.com/app/id1637186941`,
          '_blank',
          'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
        );
        browser.show();
      } else if (this.plt.is('desktop') || this.plt.is('mobileweb')) {
        const browser = await this.iab.create(
          `https://corretor.easyplan.com.br/`,
          '_blank',
          'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
        );
        browser.show();
      }
    });
  }

  SetInterval() {
    this.interval = setInterval(() => {
      this.CheckUpdate();
    }, 30000);
  }
}
