/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import * as moment from 'moment';
import CryptoJS from 'crypto-js'

import { User } from 'src/app/auth/user';
import { AuthResponse } from 'src/app/auth/authResponse';
import { ToastService } from 'src/app/services/useful/toast/toast.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(true);
  authenticationStateClient = new BehaviorSubject(false);
  authenticationStateAgent = new BehaviorSubject(false);
  authenticationStateAdministrator = new BehaviorSubject(false);
  authenticationStateOperator = new BehaviorSubject(false);
  permissions = {
    dashboard: true,
    sales: false,
    commissions: false,
    your_data: false,
    sales_support: false,
    sell: false,
    bonuses: false,
    commission_report: false,
    bonus_rule: false,
    validity_and_closing: false,
    contact: true,
    brokers: false,
    beneficiaries: false,
    users_data: false,
    doubts: false
  }
  istoast = false;

  constructor(
    private plt: Platform,
    private platform: Platform,
    private httpClient: HttpClient,
    private toast: ToastService,
    public modalController: ModalController,
    public toastController: ToastController,
    private route: ActivatedRoute,
  ) {
    this.plt.ready().then(() => {
      this.CheckToken();
    });
  }

  async CheckToken() {
    if (!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER')) {
      let user = await JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
      this.VerifyToken().subscribe(
        (res) => {
          if (res.sucesso) {
            let type;
            user.roles.forEach(element => {
              type = element
            });

            switch (type) {
              case 'ROLE_CLIENT':
                this.authenticationStateClient.next(true);
                this.authenticationState.next(false);
                this.authenticationStateOperator.next(false);
                this.authenticationStateAdministrator.next(false);
                this.authenticationStateAgent.next(false);
                break;
              case 'ROLE_AGENT':
                this.authenticationStateAgent.next(true);
                this.authenticationStateClient.next(false);
                this.authenticationStateOperator.next(false);
                this.authenticationStateAdministrator.next(false);
                this.authenticationState.next(false);
                break;
              case 'ROLE_ADMIN':
                this.authenticationStateAdministrator.next(true);
                this.authenticationStateClient.next(false);
                this.authenticationStateOperator.next(false);
                this.authenticationStateAgent.next(false);
                this.authenticationState.next(false);
                break;
              case 'ROLE_OPERATOR':
                this.authenticationStateOperator.next(true);
                this.authenticationStateClient.next(false);
                this.authenticationStateAdministrator.next(false);
                this.authenticationStateAgent.next(false);
                this.authenticationState.next(false);
                break;
              default:
                this.authenticationState.next(true);
                this.authenticationStateOperator.next(false);
                this.authenticationStateAdministrator.next(false);
                this.authenticationStateAgent.next(false);
                this.authenticationStateClient.next(false);
            }
          } else {
            this.modalController.dismiss();
            this.Logout();
            this.modalController.dismiss();
            const toast = {
              message: 'Sua sessão expirou. Faça login para acessar novamente!',
              color: 'warning',
            };
            this.toast.presentToastWithOptions(toast);
          }
        },
        (err) => {
          this.modalController.dismiss();
          this.Logout();
          this.modalController.dismiss();
          const toast = {
            message: 'Sua sessão expirou. Faça login para acessar novamente!',
            color: 'warning',
          };
          this.toast.presentToastWithOptions(toast);
        }
      );
    }
  }

  async CheckPermissions(val, url) {
    let userpermissions = val;
    let toast = {
      message: 'Acesso negado.<br />O usuário não tem permissão para acessar esta página.',
      color: 'warning',
    };
    this.permissions = JSON.parse(this.descriptografar(userpermissions));
    // console.log(this.permissions)

    switch (url) {
      case 'presentation':
        return true
      case 'dashboard':
        if (!this.permissions.dashboard && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.dashboard;
      case 'brokers':
        if (!this.permissions.brokers && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.brokers;
      case 'your-data':
        if (!this.permissions.your_data && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.your_data;
      case 'commissions':
        if (!this.permissions.commissions && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.commissions;
      case 'sales':
        if (!this.permissions.sales && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.sales;
      case 'doubts':
        if (!this.permissions.doubts && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.doubts;
      case 'sell':
        if (!this.permissions.sell && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.sell;
      case 'bonuses':
        if (!this.permissions.bonuses && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.bonuses;
      case 'beneficiaries':
        if (!this.permissions.beneficiaries && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.beneficiaries;
      case 'commission-report':
        if (!this.permissions.commission_report && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.commission_report;
      case 'bonus-rule':
        if (!this.permissions.bonus_rule && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.bonus_rule;
      case 'validity-and-closing':
        if (!this.permissions.validity_and_closing && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.validity_and_closing;
      case 'contact':
        if (!this.permissions.contact && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.contact;
      case 'sales-support':
        if (!this.permissions.sales_support && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.sales_support;
      case 'users':
        if (!this.permissions.users_data && !this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return this.permissions.users_data;
      default:
        if (this.istoast) {
          this.presentToastPermission(toast);
          this.istoast = true;
        }
        return false;
    }
  }

  descriptografar(val) {
    const bytes = CryptoJS.AES.decrypt(val, 'GjNn|%tQ_3i-n2FKwA>q2NK@L3£!{K9m>£^>3c;9\]}@6EHyu%');
    const originalVal = bytes.toString(CryptoJS.enc.Utf8);
    return originalVal;

  }

  async presentToastPermission(value) {
    const toast = await this.toastController.create({
      message: value.message,
      color: value.color,
      duration: value.time,
      position: 'top',
      cssClass: 'toast',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {
            this.istoast = false;
          },
        },
      ],
    });
    await toast.present();
  }

  IsAuthenticated() {
    // return this.authenticationState.asObservable();
    return this.authenticationState.value;
  }

  IsClientAuthenticated() {
    return this.authenticationStateClient.value;
  }

  IsAgentAuthenticated() {
    return this.authenticationStateAgent.value;
  }

  IsAdministratorAuthenticated() {
    return this.authenticationStateAdministrator.value;
  }

  IsOperatorAuthenticated() {
    return this.authenticationStateOperator.value;
  }

  Login(user): Observable<AuthResponse> {
    return (
      this.httpClient
        .post(
          `${environment.SERVER_ADDRESS}auth/signin`,
          user
        )
        .pipe(
          tap(async (res: AuthResponse) => {
            if (res.sucesso) {
              if (!this.platform.is('android') && !this.platform.is('ios')) {
                await environment.storageWeb.setItem(
                  'USER',
                  JSON.stringify(res.user)
                );
              } else {
                environment.storageMobile.setItem(
                  'USER',
                  JSON.stringify(res.user)
                );
              }
              let type;
              res.user.roles.forEach(element => {
                type = element
              });
              switch (type) {
                case 'ROLE_CLIENT':
                  this.authenticationStateClient.next(true);
                  this.authenticationState.next(false);
                  this.authenticationStateOperator.next(false);
                  this.authenticationStateAdministrator.next(false);
                  this.authenticationStateAgent.next(false);
                  break;
                case 'ROLE_AGENT':
                  this.authenticationStateAgent.next(true);
                  this.authenticationStateClient.next(false);
                  this.authenticationStateOperator.next(false);
                  this.authenticationStateAdministrator.next(false);
                  this.authenticationState.next(false);
                  break;
                case 'ROLE_ADMIN':
                  this.authenticationStateAdministrator.next(true);
                  this.authenticationStateClient.next(false);
                  this.authenticationStateOperator.next(false);
                  this.authenticationStateAgent.next(false);
                  this.authenticationState.next(false);
                  break;
                case 'ROLE_OPERATOR':
                  this.authenticationStateOperator.next(true);
                  this.authenticationStateClient.next(false);
                  this.authenticationStateAdministrator.next(false);
                  this.authenticationStateAgent.next(false);
                  this.authenticationState.next(false);
                  break;
                default:
                  this.authenticationState.next(true);
                  this.authenticationStateOperator.next(false);
                  this.authenticationStateAdministrator.next(false);
                  this.authenticationStateAgent.next(false);
                  this.authenticationStateClient.next(false);
              }
            }
          })
        )
    );
  }

  Register(form): Observable<any> {
    let formData = new FormData();

    formData.append('nome', form.name);
    formData.append('cpfCnpj', form.cpf.replace(/\D/g, ''));
    formData.append('contato.nome', form.name);
    formData.append('contato.email', form.email);
    formData.append('contato.telefone', form.phone.replace(/\D/g, ''));
    formData.append(
      'contato.telefoneAlternativo',
      form.phone.replace(/\D/g, '')
    );
    // formData.append('endereco.logradouro', form.address);
    // formData.append('endereco.numero', form.number);
    // formData.append('endereco.bairro', form.district);
    // formData.append('endereco.complemento', form.complemento);
    formData.append('endereco.cidadeID', form.city);
    // formData.append('endereco.CEP', form.postcode.replace(/\D/g, ''));
    formData.append('endereco.fiscal', 'true');
    formData.append('endereco.principal', 'true');
    formData.append('senha', form.password);
    formData.append(
      'responsavel_conta',
      form.responsavel_conta ? form.responsavel_conta : ''
    );
    formData.append(
      'cpf_cnpj_responsavel',
      form.cpf_cnpj_responsavel
        ? form.cpf_cnpj_responsavel.replace(/\D/g, '')
        : ''
    );

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/CadastrarProdutorCompleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  RegisterBank(form, produtorID): Observable<any> {
    let formData = new FormData();
    formData.append('produtorID', produtorID);
    formData.append('bancoID', form.banco);
    formData.append('agencia', form.agencia);
    formData.append('agenciaDigito', form.dv_agencia ? form.dv_agencia : '');
    formData.append('conta', form.conta_corrente);
    formData.append('contaDigito', form.dv_conta_corrente);
    formData.append('tipoContaCorrenteID', form.tipo_conta);
    formData.append('responsavel', form.name);
    formData.append('cpfCnpj', form.cpf.replace(/\D/g, ''));
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/ProdutorConta/Salvar`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  RegisterEquipe(form, produtorID): Observable<any> {
    let formData = new FormData();
    formData.append('corretoraID', form.corretora);
    formData.append('estadoID', form.state_corretora);
    formData.append('produtorID', produtorID);
    formData.append('supervisorID', form.supervisor ? form.supervisor : '');
    formData.append('supervisor', form.is_supervisor);

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/VincularEquipe`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  RegisterLogin(user): Observable<AuthResponse> {
    let formData = new FormData();
    if (user.document.replace(/\D/g, '').length === 11) {
      formData.append('cpf', user.document.replace(/\D/g, ''));
    } else {
      formData.append('cpf', user.document.replace(/\D/g, ''));
    }
    formData.append('senha', user.password);

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/AutenticarComSenha`,
        formData
      )
      .pipe(tap(async (res: AuthResponse) => { }));
  }

  Reset(form, code): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
      }),
    };

    return this.httpClient
      .put(
        `${environment.AUTH_SERVER_ADDRESS}/Saler/${code}`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetVendedor(cod): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
      }),
    };

    return this.httpClient
      .get(`${environment.AUTH_SERVER_ADDRESS}/Saler/${cod}`, httpOptions)
      .pipe(
        tap(async (res: User) => {
          if (res) {
            res.sucesso = true;
            // await environment.storage.setItem('USER', JSON.stringify(res));
            if (!this.platform.is('android') && !this.platform.is('ios')) {
              await environment.storageWeb.setItem(
                'USER',
                JSON.stringify(res)
              );
            } else {
              environment.storageMobile.setItem(
                'USER',
                JSON.stringify(res)
              );
            }
          }
        })
      );
  }

  GetValidVendedor(form): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
      }),
    };

    return this.httpClient
      .get(
        `${environment.AUTH_SERVER_ADDRESS}/Saler/?taxvat=${form.taxvat}&email=${form.email}`,
        httpOptions
      )
      .pipe(tap(async (res: User) => { }));
  }

  async PostDevice(device) {
    let user = await JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let formData = new FormData();
    formData.append('token', device.token);
    formData.append('tipo', device.tipo);
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/UsuarioToken/Salvar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  async Logout() {
    // environment.storage.removeItem('USER');
    if (!this.platform.is('android') && !this.platform.is('ios')) {
      await environment.storageWeb.removeItem('USER');
    } else {
      await environment.storageMobile.removeItem('USER');
    }
    this.authenticationState.next(true);
    this.authenticationStateClient.next(false);
    this.authenticationStateAgent.next(false);
    this.authenticationStateAdministrator.next(false);
    this.authenticationStateOperator.next(false);
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('Um erro ocorreu:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Código retornado pelo backend ${error.status}, ` +
      //   `corpo era: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Algo de errado aconteceu; Por favor, tente novamente mais tarde!'
    );
  }

  updateUser(user): Observable<any> {
    let formData = new FormData();

    formData.append('produtorID', user.produtorID);
    formData.append('nome', user.nome);
    formData.append('cpfCnpj', user.cpfCnpj.replace(/\D/g, ''));
    formData.append(
      'contato.nome',
      user.contatos.length > 0 ? user.contatos[0].nome : ''
    );
    formData.append(
      'contato.email',
      user.contatos.length > 0 ? user.contatos[0].email : ''
    );
    formData.append(
      'contato.telefone',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'contato.telefoneAlternativo',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'enderecoID',
      user.enderecos.length > 0 ? user.enderecos[0].enderecoID : ''
    );
    // formData.append('endereco.logradouro', user.enderecos[0].logradouro);
    // formData.append(
    //   'endereco.numero',
    //   user.enderecos[0].numero === 'null' ? null : user.enderecos[0].numero
    // );
    // formData.append('endereco.bairro', user.enderecos[0].bairro);
    // formData.append(
    //   'endereco.complemento',
    //   user.enderecos[0].complemento === 'null'
    //     ? null
    //     : user.enderecos[0].complemento
    // );
    formData.append(
      'endereco.cidadeID',
      user.enderecos.length > 0 ? user.enderecos[0].cidadeID : ''
    );
    // formData.append('endereco.CEP', user.enderecos[0].CEP.replace(/\D/g, ''));
    formData.append('endereco.fiscal', 'true');
    formData.append('endereco.principal', 'true');

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/CadastrarProdutorCompleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  updateEquipe(user, stateId): Observable<any> {
    let formData = new FormData();
    formData.append(
      'corretoraID',
      user.equipes.length > 0 ? user.equipes[0].corretora.corretoraID : ''
    );
    formData.append('estadoID', stateId);
    formData.append('produtorID', user.produtorID);
    formData.append(
      'supervisorID',
      user.equipes.length > 0 ? user.equipes[0].corretora.supervisor : ''
    );

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/VincularEquipe`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  updateEndereco(user): Observable<any> {
    let formData = new FormData();

    formData.append('produtorID', user.produtorID);
    formData.append('nome', user.nome);
    formData.append('cpfCnpj', user.cpfCnpj.replace(/\D/g, ''));
    formData.append(
      'contato.nome',
      user.contatos.length > 0 ? user.contatos[0].nome : ''
    );
    formData.append(
      'contato.email',
      user.contatos.length > 0 ? user.contatos[0].email : ''
    );
    formData.append(
      'contato.telefone',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'contato.telefoneAlternativo',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'enderecoID',
      user.enderecos.length > 0 ? user.enderecos[0].enderecoID : ''
    );
    // formData.append('endereco.logradouro', user.enderecos[0].logradouro);
    // formData.append(
    //   'endereco.numero',
    //   user.enderecos[0].numero === 'null' ? null : user.enderecos[0].numero
    // );
    // formData.append('endereco.bairro', user.enderecos[0].bairro);
    // formData.append(
    //   'endereco.complemento',
    //   user.enderecos[0].complemento === 'null'
    //     ? null
    //     : user.enderecos[0].complemento
    // );
    formData.append(
      'endereco.cidadeID',
      user.enderecos.length > 0 ? user.enderecos[0].cidadeID : ''
    );
    // formData.append('endereco.CEP', user.enderecos[0].CEP.replace(/\D/g, ''));
    formData.append('endereco.fiscal', 'true');
    formData.append('endereco.principal', 'true');

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/CadastrarProdutorCompleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  updatePassword(user, password): Observable<any> {
    let formData = new FormData();

    formData.append('produtorID', user.produtorID);
    formData.append('nome', user.nome);
    formData.append('cpfCnpj', user.cpfCnpj.replace(/\D/g, ''));
    formData.append(
      'contato.nome',
      user.contatos.length > 0 ? user.contatos[0].nome : ''
    );
    formData.append(
      'contato.email',
      user.contatos.length > 0 ? user.contatos[0].email : ''
    );
    formData.append(
      'contato.telefone',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'contato.telefoneAlternativo',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'enderecoID',
      user.enderecos.length > 0 ? user.enderecos[0].enderecoID : ''
    );
    // formData.append('endereco.logradouro', user.enderecos[0].logradouro);
    // formData.append(
    //   'endereco.numero',
    //   user.enderecos[0].numero === 'null' ? null : user.enderecos[0].numero
    // );
    // formData.append('endereco.bairro', user.enderecos[0].bairro);
    // formData.append(
    //   'endereco.complemento',
    //   user.enderecos[0].complemento === 'null'
    //     ? null
    //     : user.enderecos[0].complemento
    // );
    formData.append(
      'endereco.cidadeID',
      user.enderecos.length > 0 ? user.enderecos[0].cidadeID : ''
    );
    // formData.append('endereco.CEP', user.enderecos[0].CEP.replace(/\D/g, ''));
    formData.append('endereco.fiscal', 'true');
    formData.append('endereco.principal', 'true');
    formData.append('senha', password);

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/CadastrarProdutorCompleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  updateBank(user): Observable<any> {
    let formData = new FormData();
    formData.append(
      'produtorContaID',
      user.contas.length > 0 ? user.contas[0].produtorContaID : ''
    );
    formData.append('produtorID', user.produtorID);
    formData.append(
      'bancoID',
      user.contas.length > 0 ? user.contas[0].bancoID : ''
    );
    formData.append(
      'agencia',
      user.contas.length > 0 ? user.contas[0].agencia : ''
    );
    formData.append(
      'agenciaDigito',
      user.contas.length > 0 ? user.contas[0].agenciaDigito : ''
    );
    formData.append(
      'conta',
      user.contas.length > 0 ? user.contas[0].conta : ''
    );
    formData.append(
      'contaDigito',
      user.contas.length > 0 ? user.contas[0].contaDigito : ''
    );
    formData.append(
      'tipoContaCorrenteID',
      user.contas.length > 0 ? user.contas[0].tipoContaCorrenteID : ''
    );
    formData.append('responsavel', user.nome);
    formData.append('cpfCnpj', user.cpfCnpj.replace(/\D/g, ''));
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/ProdutorConta/Salvar`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateDocument(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.pandadoc.url}`,
        {
          name: form.name,
          folder_uuid: 's2UxpAnCDpGi5UaG4SALXg',
          template_uuid: 'cvBQJvZ2wa33rAjJDobDp2',
          recipients: [
            {
              email: form.email,
              first_name: form.name.slice(0, form.name.indexOf(' ')),
              last_name: form.name.slice(
                form.name.indexOf(' '),
                form.name.length + 1
              ),
              role: 'Contratada',
            },
          ],
          fields: {
            razaosocial: {
              value: form.name,
            },
          },
        },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  DocumentStatus(documentID) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .get(`${environment.pandadoc.url}/${documentID}`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  SendDocument(documentID) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.pandadoc.url}/${documentID}/send`,
        {
          subject: 'CONTRATOS EASYPLAN ADMINISTRADORA',
          message: `
          Olá, parceiro (a)!

Estamos entrando em contato para informar que, devido a novas atualizações na comercialização dos nossos produtos, precisamos solicitar a realização da assinatura digital dos nossos contratos.

OBS: O responsável legal quem deve assinar. 

Agradecemos pela sua confiança em nossa empresa e esperamos continuar proporcionando os melhores benefícios para você e sua equipe.

Fique à vontade para entrar em contato conosco para esclarecer quaisquer dúvidas.
          `,
          silent: false,
          forwarding_settings: {
            forwarding_allowed: true,
            forwarding_with_reassigning_allowed: true,
          },
        },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  DeleteDocument(documentID) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .delete(`${environment.pandadoc.url}/${documentID}/`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }


  VerifyToken(): Observable<any> {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': user.accessToken,
      }),
    };
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}auth/verifyToken`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUserPermissions() {
    let user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': user.accessToken,
      }),
    };

    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}user/${user.id}/permissions`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }
}
