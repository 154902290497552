import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BonusesService {

  public user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));

  public httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': this.user.accessToken,
    }),
  };

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
  ) { }


  AddLoteBonuses(file): Observable<any> {
    let formData = new FormData();
    formData.append('file', file);

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}loteBonuses`,
        formData,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateLoteBonuses(form, id): Observable<any> {
    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}loteBonuses/${id}`,
        form,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetLoteBonusesAll(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}loteBonuses`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetLoteBonusesAllSearch(dados): Observable<any> {
    let Data = {
      status: dados.status,
      dataInicio: dados.dataInicio,
      dataFim: dados.dataFim
    }
    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}loteBonuses/search`,
        Data,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetLoteBonuses(id): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}loteBonuses/${id}`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  DeleteLoteBonuses(id): Observable<any> {
    return this.httpClient
      .delete(
        `${environment.SERVER_ADDRESS}loteBonuses/${id}`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }
}
