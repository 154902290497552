import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { AuthAdministratorGuard } from './guards/authAdministrator/authAdministrator.guard';
import { AuthAgentGuard } from './guards/authAgent/authAgent.guard'; 
import { AuthClientGuard } from './guards/authClient/authClient.guard'; 
import { AuthOperatorGuard } from './guards/authOperator/authOperator.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/public/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'administrator',
    canActivate: [AuthAdministratorGuard],
    loadChildren: () => import('./modules/administrator/administrator.routing.module').then(m => m.AdministratorRoutingModule)
  },
  {
    path: 'agent',
    canActivate: [AuthAgentGuard],
    loadChildren: () => import('./modules/agent/agent.routing.module').then(m => m.AgentRoutingModule)
  },
  {
    path: 'client',
    canActivate: [AuthClientGuard],
    loadChildren: () => import('./modules/client/client.routing.module').then(m => m.ClientRoutingModule)
  },
  {
    path: 'operator',
    canActivate: [AuthOperatorGuard],
    loadChildren: () => import('./modules/operator/operator.routing.module').then(m => m.OperatorRoutingModule)
  },
  {
    path: 'presentation',
    loadChildren: () => import('./pages/public/presentation/presentation.module').then(m => m.PresentationPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
